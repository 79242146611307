// export const GLOBAL_API = "https://ec-cardapp.com/nodeapi/ec-card-gateway/api";
// export const GLOBAL_API = "https://ec-card.icycastle.com/nodeapi/ec-card-gateway/api";
export const GLOBAL_API = "https://stg.ec-cardapp.com/nodeapi/ec-card-gateway/api";

export const APP_VERSION = "2.0.0";
export const GLOBAL_LOADER = "GLOBAL_LOADER";
export const GLOBAL_ALERT = "GLOBAL_ALERT";
export const REGISTRAION_CONDITIONAL_AGE = 25;
export const GLOBAL_ALERT_REMOVE = "GLOBAL_ALERT_REMOVE";
export const GLOBAL_API_DATA = "GLOBAL_API_DATA";
export const USER_MSG_COUNT = "USER_MSG_COUNT";
export const IP_KEY =
  "9fb6b9bd13fad37445e7f4586f3ca269394a8a2609edaf94706a9d16";
export const GOOGLE_MAPS_API_KEY = "AIzaSyBFO6LYyZrlGo3p7A1hHZt2TcQZvuVvw-M";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyD0jHzkLOznbZuIgSdXprZgNRKLL01PIrE";

export const GENDER_LIST = [
  {
    id: "btnMale",
    value: "male",
    text: "M",
  },
  {
    id: "btnFemale",
    value: "female",
    text: "F",
  },
  {
    id: "btnOther",
    value: "others",
    text: "Other",
  },
  {
    id: "btnUnspecified",
    value: "not_disclosed",
    text: "Do not wish to disclose",
  },
];

export const MAP_SHOW_LIST = [
  {
    id: "both",
    value: "all",
    text: "All",
  },

  {
    id: "yes",
    value: "yes",
    text: "Yes",
  },
  {
    id: "no",
    value: "no",
    text: "No",
  },
];

export const PAGE_ENTRY_SIZE = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 },
];

export const ADMIN_SIDEBAR = [
  {
    text: "Manage Users",
    link: "manage-users",
    icon: "fas fa-users",
  },
  {
    text: "Manage Venue",
    icon: "fas fa-book",
    value: "manageVenue",
    subMenu: [
      {
        text: "Manage Stores",
        link: "manage-stores",
        icon: "fas fa-home",
        value: "manage-stores",
      },
      {
        text: "Manage Kiosk",
        link: "manage-kiosk",
        icon: "fas fa-home",
        value: "manage-kiosk",
      },
      {
        text: "Product Catalouge",
        link: "manage-product-catalouge",
        icon: "fas fa-home",
        value: "manage-product-catalouge",
      },
    ],
  },
  {
    text: "Manage Access-Codes",
    link: "access-codes",
    icon: "fas fa-address-card",
  },
  {
    text: "Quiz Videos",
    link: "quiz-videos",
    icon: "fas fa-video",
  },
  {
    text: "Quiz Questions",
    link: "quiz",
    icon: "fas fa-question",
  },
  {
    text: "Manage Admins",
    link: "manage-admins",
    icon: "fas fa-users-cog",
  },

  {
    text: "Manage Education",
    link: "education-management",
    icon: "fas fa-book",
  },
  {
    text: "Manage Notifications",
    link: "notification-management",
    icon: "fas fa-book",
  },
  {
    text: "Reports",
    icon: "fas fa-book",
    value: "reports",
    subMenu: [
      {
        text: "Venues Report",
        link: "venues-report",
        icon: "fas fa-book",
        value: "stores-report",
      },
      {
        text: "Users Report",
        link: "users-report",
        icon: "fas fa-book",
        value: "users-report",
      },
      {
        text: "Users Data Report",
        link: "users-data-report",
        icon: "fas fa-book",
        value: "users-data-report",
      },
    ],
  },

  {
    text: "Support",
    link: "support",
    icon: "fas fa-book",
  },
  {
    text: "Configuration",
    link: "manage-configration",
    icon: "fas fa-book",
  },
];

export const SUPERADMIN_SIDEBAR = [
  {
    text: "Manage Organisations",
    link: "manage-organisations",
    icon: "fas fa-building",
  },
  {
    text: "Manage Admin",
    link: "manage-admin",
    icon: "fas fa-users-cog",
  },
  {
    text: "Manage Super Admin",
    link: "manage-superadmin",
    icon: "fas fa-user-shield",
  },
];

export const UPLOAD_IMAGE = "../assets/wallpaper/upload-image.png";

export const ORG_DETAILS = "ORG_DETAILS";
export const ADD_ORG_NAME = "ADD_ORG_NAME";

export const STORES_DROPDOWN_LIST = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Stores",
    value: "stores",
  },
  {
    label: "Kiosk",
    value: "ivts",
  },
];

export const ZONES_LIST = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Stores",
    value: "stores",
  },
  {
    label: "Kiosk",
    value: "ivts",
  },
];

export const TERMSMODALCONTENT = {
  heading: "Welcome to the eC-Card",
  body: "Please note this app is not for emergency contraceptives. If you need these, please contact your local pharmacy or sexual health service directly",
};

export const MANAGESTOCKTYPES = {
  MANAGESTOCKS: "Manage Stocks",
  STOCKTRANSACTIONS: "Stock Transactions",
};

export const addProductBtnHeight = "56px";
